import { SOLICITUD_MATERIAL } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'
import { toDate, toDateTime } from '@/utils/date'

export default {
  async insert (Vue, formData, idparteTrabajo, usuarioIdempleado) {
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(idparteTrabajo)
    const idsolicitudMaterial  = uuidv4()
    await Vue.$offline.solicitudMaterial.insertSync({
      idsolicitud_material: idsolicitudMaterial,
      estado: 1,
      idest_solicitud_material: SOLICITUD_MATERIAL.estados.pendienteValidacion,
      idparte_trabajo: idparteTrabajo,
      idorden_trabajo: parteTrabajo.parte_trabajo.idorden_trabajo,
      idempleado: usuarioIdempleado,
      fsolicitud: toDateTime(formData.fsolicitud),
      fsolicitada_entrega: toDate(formData.fsolicitada_entrega),
      descripcion: formData.descripcion,
      observaciones: formData.observaciones,
      idgrupo_empleado: formData.idgrupo_empleado,
      atencion_de: formData.atencion_de,
    })
    return idsolicitudMaterial
  },
}
