<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <solicitud-material-form
          v-if="dbReady"
          :idparte-trabajo="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import SolicitudMaterialForm from '../components/SolicitudMaterialForm'
import Data from './SolicitudMaterialAddData'
import { get } from 'vuex-pathify'
import { NextRoute } from '@/router'

export default {
  components: {
    SolicitudMaterialForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Crear solicitud de material'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const inserted = await Data.insert(
        this,
        this.formData,
        this.routeParams.idparte_trabajo,
        this.usuarioIdempleado,
      )
      const solicitudMaterial = await this.$offline.solicitudMaterial.selectParteDeSolicitud(inserted)
      this.$appRouter.replace({
        name: 'offline__articulo-list',
        params: {
          nextRoute: NextRoute(
            'offline__parte-trabajo-solicitud-material__material-add',
            {
              idsolicitud_material: inserted,
              idparte_trabajo: this.routeParams.idparte_trabajo,
              idarticulo: null,
            },
            {}, // query
            true,
          ),
        },
        query: {
          idttarifa: solicitudMaterial.orden_trabajo.idttarifa,
          idbanco_precio: solicitudMaterial.orden_trabajo.idbanco_precio,
        }
      })
    },
  },
}
</script>
